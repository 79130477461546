.textAlign{
    text-align:left;
    color:black;
    margin-left: 0px;
    margin-top: 0px;
}
.textAlign1{
    color:rgba(0, 0, 0, 0.87);
}
 .container{
    display: flex;
 }
 .notification{
     /*padding-left: 2%;*/
     width: 30%;
     color:rgba(0, 0, 0, 0.89);
     font-Size: 14px;
     line-Height: 25px;
     display: inline-block;
 }
 .helpText {
    color: #304d63;
    font-Size: 14px;
    line-Height: 16px;
    margin-Left: 34.7%;
    text-Align: right;
}
 /* .names{
     
     margin-top: 0px;
     width: 110px;
     height: 16px;
     font-family: Roboto;
     font-size: 16px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1;
     letter-spacing: normal;
     color: rgba(0, 0, 0, 0.87);
     margin-right:1px;
 } */
 .nameTitle{     
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    margin-top: 10px;
    color: #4f4f4f;
}

.name{     
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.89);
}

 .status{
    width: 300px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-top: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    color: #4f4f4f;
} 
.Name{
   margin-top:25px;
   width: 38px;
   height: 19px;
   font-family: Roboto, Arial, Helvetica, sans-serif;
   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.36;
   letter-spacing: normal;
   color: #4f4f4f;
   margin-left:5px;
 
}
.cont{
   
    width: 975px;
    height:359px;
}

 .placeText { 
     width: 70%;
     font-size: 15px;
     line-height: 24px; 
     letter-spacing: 0.44px
    }


 


 
