.Last_4_digit {
    width: 294px;
    border-radius: 4px;
    border: 1px solid #c6cacc;
    float: right;
    background-color: #f1f1f1;
}
iframe{
    position: inherit !important;
}
.td_style1 {
    width: 149px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: .4px;
    color: #787885;
    padding-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.td_style{
    width: 191px;
    height: 32px;
    margin: 0 2px 0 0;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.44px;
    text-align: left;
    color: #19191d;
  }
.disabledReenrollPlanSelection{
    pointer-events: none;
    opacity: 0.8;
}
.listStyle{
    
    text-indent:-1.5em;
    list-style-type: disc;
    list-style-position:inside;
    padding:5px 0px 0px 20px;
    
}